
import { gql } from "graphql-tag";

export const PLP_BANNER_FRAGMENT = gql`
  fragment PLPBabnner on Plp_banner {
    image_link {
      ...documentLink
      ...externalLink
    }
    image
    image_1_link {
      ...documentLink
      ...externalLink
    }
    image_2_link {
      ...documentLink
      ...externalLink
    }
    image_1
    image_2
    display_banners
  }
`;

export default {
  props: { banner: { type: Object, default: () => {} } },

  computed: {
    bannerImgProps() {
      const img = this.$device.isMobile
        ? this.banner.image?.mobile
        : this.banner.image;

      const imgDimensions = this.$device.isMobile
        ? img?.dimensions
        : img.mobile.dimensions;

      return {
        src: img?.url,
        alt: img?.alt,
        width: imgDimensions?.width,
        ...this.getImageDimensions(imgDimensions, 900),
      };
    },
  },
};
